import Layout from '@components/Layout';
import styles from '@styles/404.module.css';

import { Roboto, Lato } from 'next/font/google';
import Head from 'next/head';

const roboto = Roboto({
  weight: '400',
  subsets: ['latin'],
});

const lato = Lato({
  weight: '700',
  subsets: ['latin'],
});

export default function Custom404() {
  return (
    <>
      <Head>
        <title>404 - Webbyrå baserad i Jönköping | Zerio</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <Layout className={styles.main}>
        <h1 className={`${styles.errorCode} ${lato.className}`}>404</h1>
        <div className={styles.divider}></div>
        <h2 className={`${styles.errorText} ${roboto.className}`}>
          Denna sida kunde inte hittas
        </h2>
      </Layout>
    </>
  );
}
